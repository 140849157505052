import {Component, computed, input, OnChanges, OnInit, signal, SimpleChanges} from '@angular/core';
import { Observable } from 'rxjs';
import { Translations } from '../../translations/translations';
import { CitizenService } from '../services/citizen.service';
import { MatButton } from '@angular/material/button';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'iv-citizen-specialinformation',
    template: `
        <ul *ngIf="showInfo(); else buttonSpecialInformation" class="citizen-specialinformation">
            <li *ngFor="let info of specialInformation | async" class="citizen-specialinformation__item">{{ info }}</li>
        </ul>
        <ng-template #buttonSpecialInformation>
            <button mat-raised-button color="accent" type="button" (click)="showBtnClicked.set(true)">${Translations.administration.citizen.showSpecialInformation}</button>
        </ng-template>
    `,
    standalone: true,
    imports: [NgIf, NgFor, MatButton, AsyncPipe]
})
export class CitizenSpecialinformationComponent implements OnInit, OnChanges {
    citizenNo = input('');
    show = input(false);

    showBtnClicked = signal(false);
    showInfo = computed(() => this.show() || this.showBtnClicked());

    specialInformation: Observable<string[]>;

    constructor(public citizenService: CitizenService) {}

    ngOnInit() {
        this.fetchSpecialInformation();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.citizenNo && !changes.citizenNo.firstChange) {
            this.fetchSpecialInformation();
        }
    }

    private fetchSpecialInformation() {
        this.specialInformation = this.citizenService.getCitizenSpecialInformation(this.citizenNo());
    }
}
